<template>
  <section class="app-container">
    <Filter
      @search="handleSearch"
      @export-click="exportClick"
      @refresh-table="handleResetTable"
    />
    <CertificateList ref="certificateListRef"></CertificateList>
  </section>
</template>
<script>
import { defineComponent, ref } from "vue";
import Filter from "./components/Filter.vue";
import CertificateList from "./components/CertificateList";
import { downloadFile } from "@/utils/common";
import { useGlobalPropertyHook } from "@/hooks/common";

export default defineComponent({
  name: "exportCertificate",
  components: {
    CertificateList,
    Filter,
  },
  setup() {
    const { $api } = useGlobalPropertyHook();

    const certificateListRef = ref();
    const handleSearch = (searchParams) => {
      certificateListRef.value.searchClick(searchParams);
    };
    const handleResetTable = () => {
      certificateListRef.value.doSearch();
    };

    const exportClick = (searchParams) => {
      $api.certificationExport(searchParams).then((res) => {
        downloadFile(res.file, res.filename);
      });
    };
    return {
      certificateListRef,
      handleSearch,
      handleResetTable,
      exportClick,
    };
  },
});
</script>
<style scoped lang="less">
.app-container {
  height: 100%;
  padding: 10px 20px;
  background: #ffffff;
  border-radius: 8px;
}
</style>
