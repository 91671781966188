<template>
  <section class="top-filter">
    <div class="mb12">
      <FilterLayout
          @doReset="resetForm"
          @doSearch="doSearch"
          ref="filterLayoutRef"
          @showMoreForm="showMoreForm"
          :moreBtnVisible="moreBtnVisible"
          :isShowMoreFlag="isShowMoreList">
        <template #customform>
          <a-form
              ref="formRef"
              class="form rms-form box"
              layout="vertical"
              :model="formState"
          >
            <template v-for="(item,index) in queryList" :key="index">
              <template v-if="index<=4">
                <a-form-item :label="item.code" name="name" class="rms-form-item">
                  <template v-if="item.type==='3'">
                    <a-input
                        v-model:value="formState[item.inputDataType]"
                        :placeholder="getPlaceholder(item.inputDataType)"
                        enter-button
                        allowClear
                        autocomplete="off"
                    />
                  </template>
                  <template v-if="item.type==='5'">
                    <SelectWithAll
                        v-model:value="formState[item.inputDataType]"
                        :options="getOptions(item.inputDataType)"
                        placeholder="请选择"
                        mode="multiple"
                        showArrow
                        allowClear
                        @change="getSelectChange(item.inputDataType)"
                    >
                    </SelectWithAll>
                  </template>
                </a-form-item>
              </template>
            </template>
            <MoreFormList :visible="isShowMoreList" @open="openFormSortBox" @close="closeMoreForm" @moreFormSearch="moreSeachHanlder">
              <template v-for="(item,index) in queryList" :key="index">
                <template v-if="index>4">
                  <a-form-item :label="item.code" name="name" class="rms-form-item">
                    <template v-if="item.type==='3'">
                      <a-input
                          v-model:value="formState[item.inputDataType]"
                          placeholder="Name/GGID"
                          enter-button
                          allowClear
                          autocomplete="off"
                      />
                    </template>
                    <template v-if="item.type==='5'">
                      <SelectWithAll
                          v-model:value="formState[item.inputDataType]"
                          :options="getOptions(item.inputDataType)"
                          placeholder="请选择"
                          mode="multiple"
                          showArrow
                          allowClear
                          @change="getSelectChange(item.inputDataType)"
                      >
                      </SelectWithAll>
                    </template>
                  </a-form-item>
                </template>
              </template>
            </MoreFormList>
          </a-form>
        </template>
        <template #operationBtns>
          <div class="buttons-wrap right">
            <Tooltip title="导出">
              <a-button class="tight" type="primary" ghost @click="exportClick" v-auth="['system:certificate:export']">
                <ExportOutlined />
              </a-button>
            </Tooltip>
          </div>
        </template>
      </FilterLayout>
    </div>
    <FormModalBox v-model:visible="isShowFormSortBox" :sortFlag="true" :checkList="checkList" @closeModal="closeModal" @reset="queryFormList"></FormModalBox>
  </section>
</template>
<script>
import { defineComponent, reactive, ref, onMounted, onActivated } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import { getValueAndOps } from "@/utils/orgSelectData"
import {cloneDeep, debounce} from "lodash";
import { ExportOutlined } from "@ant-design/icons-vue";
import Tooltip from '@/components/Tooltip'
import {dictionaryOpt} from '@/utils/common'
import MoreFormList from '@/components/MoreFormList'
import FormModalBox from '@/components/FormModalBox'

export default defineComponent({
  name: "TopFilter",
  components: { ExportOutlined,Tooltip,MoreFormList,FormModalBox },
  emits: ["search", "export-click", "refresh-table"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const formRef = ref();

    const resetForm = () => {
      formRef.value.resetFields();
      updateParams()
      ctx.emit("search", currentState);
      getOrgList();
    };
    const formState = reactive({
      status: undefined,
      condition: "",
      certificateCondition: "",
      orgTwoList: [],
      orgFiveOneList: [],
      orgThreeTwoList: [],
      orgFourOneOneList: [],
    });
    const orgOpsObj = reactive({
      orgTwoOps: [],
      orgFiveOneOps: [],
      orgThreeTwoOps: [],
      orgFourOneOneOps: []
    });
    const getOrgList = () => {
      $api.getOrgTwo_Three_Four_FiveList().then((res) => {
        const { orgFourOneOneList, orgTwoList, orgFiveOneList, orgThreeTwoList } = res.data;
        orgOpsObj.orgTwoOps = dictionaryOpt(orgTwoList);
        orgOpsObj.orgFiveOneOps = dictionaryOpt(orgFiveOneList);
        orgOpsObj.orgThreeTwoOps = dictionaryOpt(orgThreeTwoList);
        orgOpsObj.orgFourOneOneOps = dictionaryOpt(orgFourOneOneList);
      })
    };
    const orgThreeTwoChange = (type) => {
      const formOrgData = {
        orgTwoList: formState.orgTwoList,
        orgFiveOneList: formState.orgFiveOneList,
        orgThreeTwoList: formState.orgThreeTwoList,
        orgFourOneOneList: formState.orgFourOneOneList
      }
      const { orgsValue, orgOpsList } = getValueAndOps(orgAllList.value, formOrgData, orgOpsObj, type)
      Object.assign(formState, orgsValue)
      Object.assign(orgOpsObj, orgOpsList)
    };
    const orgAllList = ref([])
    const getOrgRelatedList = () => {
      $api.getAllOrgRelatedList().then((res) => {
        orgAllList.value = res.data
      })
    }
    let currentState = {};
    const updateParams = () => {
      currentState = cloneDeep(formState)
    };
    const doSearch = () => {
      updateParams()
      ctx.emit("search", currentState);
    };
    const exportClick = () => {
      ctx.emit("export-click", currentState);
    };

    const queryList = ref([])
    const checkList = ref([])
    const moreBtnVisible = ref(false)
    const queryFormList = (data) => {
      $api.queryFilterSort({
        firstMenu: 'basicDataManagement',
        secondMenu: 'certInfo',
        isQueryDefault: data.isQueryDefault
      }).then((res) => {
        if(data.isQuery){
          queryList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
          queryList.value.length > 5?moreBtnVisible.value = true :moreBtnVisible.value = false
          checkList.value=[...queryList.value]
        }else{
          checkList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
        }
        console.log(queryList.value)
        console.log(checkList.value)
      });
    };
    const getPlaceholder = (data) => {
      switch (data) {
        case 'condition':
          return 'Name/GGID'
        case 'certificateCondition':
          return 'Certificate Name/Group Name'
        default :
          ''  // 如果没
      }
    }
    const getOptions = (data)=> {
      switch (data) {
        case 'orgTwoList':
          return orgOpsObj.orgTwoOps
        case 'orgThreeTwoList':
          return orgOpsObj.orgThreeTwoOps
        case 'orgFourOneOneList':
          return orgOpsObj.orgFourOneOneOps
        case 'orgFiveOneList':
          return orgOpsObj.orgFiveOneOps
        default :
          ''  // 如果没
      }
    }
    const moreSeachHanlder = () => {
      doSearch()
      closeMoreForm()
    }
    const getSelectChange = debounce((data) => {
      switch (data) {
        case 'orgTwoList':
          return orgThreeTwoChange('orgTwo')
        case 'orgThreeTwoList':
          return orgThreeTwoChange('orgThreeTwo')
        case 'orgFourOneOneList':
          return orgThreeTwoChange('orgFourOneOne')
        case 'orgFiveOneList':
          return orgThreeTwoChange('orgFiveOne')
        default :
          ''  // 如果没
      }
    },500)
    const isShowMoreList = ref(false)
    const isShowFormSortBox = ref(false)
    const showMoreForm = (data) => {
      isShowMoreList.value = data
    }
    const openFormSortBox = () => {
      isShowFormSortBox.value = true
    }
    const closeMoreForm = (data) => {
      if(data){
        console.log(123)
      }
      isShowMoreList.value = false
    }
    const closeModal = ()=> {
      isShowFormSortBox.value = false
    }
    onMounted(() => {
      // nextTick(doSearch);
      getOrgList();
      getOrgRelatedList()
      setTimeout(queryFormList({isQueryDefault:0,isQuery: true}),500)
    });
    onActivated(() => {
      ctx.emit("refresh-table");
    });

    return {
      formState,
      formRef,
      doSearch,
      resetForm,
      exportClick,
      orgThreeTwoChange,
      orgOpsObj,
      updateParams,
      queryFormList,
      queryList,
      checkList,
      getPlaceholder,
      getOptions,
      getSelectChange,
      isShowMoreList,
      showMoreForm,
      moreBtnVisible,
      openFormSortBox,
      isShowFormSortBox,
      closeMoreForm,
      closeModal,
      moreSeachHanlder
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;
  .feature-line{
    text-align: right;
    margin-top:-20px;
    margin-bottom: -5px;
  }
}
.buttons-wrap{
  :deep(.anticon){
    font-size: 20px;
  }
}
</style>
